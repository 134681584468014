import { useDispatch } from "react-redux";
import { fetchGuilds } from "../features/guilds/guildsSlice";

import buildUrl from "@googlicius/build-url";
import WindowOpener from "react-window-opener";
import { DiscordLogo } from "./DiscordLogo";
import { toast } from "react-toastify";

const BuildDiscordBotAuthURL = (guild_id) => {
  return buildUrl("https://discord.com/api/oauth2/authorize", {
    queryParams: {
      response_type: "code",
      client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
      permissions: "2147994688",
      scope: "bot applications.commands",
      guild_id: guild_id,
      // state: "5773059ghq983habn",
      redirect_uri: `${process.env.REACT_APP_ROOT_PATH}/auth/discord/bot`,
    },
  });
};

const AddDiscordGuild = ({ text, guildId, popup, className }) => {
  const dispatch = useDispatch();
  const discordBotAuthURL = BuildDiscordBotAuthURL(guildId);

  const onBotAddResponse = (err, res) => {
    if (err) {
      return;
    }

    let fetchPromise = dispatch(fetchGuilds({ refresh: true, guild_id: res?.guild_id })).then((result) => {
      if (result.meta.requestStatus === "rejected") {
        return Promise.reject(result.error);
      }
    });

    toast.promise(fetchPromise, {
      pending: "Refreshing Available Servers...",
      success: "Server Refresh Successful!",
      error: "Could not refresh servers. Please try again later or contact support.",
    });
  };

  if (popup) {
    return (
      <WindowOpener url={discordBotAuthURL} bridge={onBotAddResponse} width="450" height="600">
        {text ? (
          text
        ) : (
          <button className="flex justify-center py-2 pl-3 pr-9 border-t border-gray-400 w-full font-bold text-blurple hover:text-blurple-light transition">
            <DiscordLogo classNames="mr-3 h-6" />
            Invite Bot to Server
          </button>
        )}
      </WindowOpener>
    );
  } else {
    return (
      <button
        onClick={() => {
          window.location.href = discordBotAuthURL;
        }}
        className={
          className
            ? className
            : "flex justify-center py-2 pl-3 pr-9 border-t border-gray-400 w-full font-bold text-blurple hover:text-blurple-light transition"
        }
      >
        {text ? (
          text
        ) : (
          <>
            <DiscordLogo classNames="mr-3 h-6" />
            Invite Bot to Server
          </>
        )}
      </button>
    );
  }
};

export { AddDiscordGuild, BuildDiscordBotAuthURL };
