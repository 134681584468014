import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectManagedGuilds, selectInstalledGuilds } from "../../features/guilds/guildsSlice";
// import { fetchGoogleAccounts, selectGoogleAccounts } from "../../features/google/googleSlice";

import { GuildList } from "./GuildList";
import { StatusIcon } from "./StatusIcon";
// import { AddGoogleCalendar } from "../../components/AddGoogleCalendar";

const WelcomeWizard = () => {
  const dispatch = useDispatch();
  const managedGuilds = useSelector(selectManagedGuilds);
  const installedGuilds = useSelector(selectInstalledGuilds);
  // const googleAccount = useSelector(selectGoogleAccounts);

  useEffect(() => {
    // dispatch(fetchGoogleAccounts());
    // TODO bekit - get the followed accounts here?
  }, [dispatch]);

  let guilds_status = installedGuilds.length > 0;
  let activitypub_status = false;
  // TODO bekit - get proper status
  // let google_status = googleAccount.length > 0;

  return (
    <div>
      <h1 className="text-3xl font-bold text-center py-8">Let's Get Started!</h1>

      {/* Add a Server */}
      <div className="flex items-center">
        <StatusIcon status_ok={guilds_status} />
        <span className="text-lg text-green-500 font-bold pl-2">{guilds_status ? "DONE: " : null}</span>
        <h2 className="text-lg font-semibold py-4 pl-2">Add a Discord Server</h2>
      </div>
      {guilds_status ? null : managedGuilds.length === 0 ? (
        <div className="bg-gray-100 rounded-lg mx-auto max-w-md shadow py-4 px-8 text-center">
          <h2 className="text-red-500 font-semibold text-lg">No Discord Servers Available For Installation</h2>
          <p className="mt-4 sm:px-10 text-gray-500">
            You must have the <span className="font-semibold">"Manage Server"</span> permission on at least one server
            in order to install Pachy Bot.
          </p>
        </div>
      ) : (
        <GuildList guilds={managedGuilds} />
      )}

      {/* Add a Google Account */}
      {/* <div className={`flex items-center ${guilds_status ? "" : "border-t mt-14"} border-gray-300`}>
        <StatusIcon status_ok={google_status} />
        <span className="text-lg text-green-500 font-bold pl-2">{google_status ? "DONE: " : null}</span>
        <h2 className="text-lg font-semibold py-4 pl-2">Add a Google Account</h2>
      </div>
      {google_status ? <div></div> : <GoogleAccountPrompt />} */}

      {/* Create your first Notifier */}
      <div className={`flex items-center ${activitypub_status ? "" : "border-t mt-14"} border-gray-300`}>
        <StatusIcon status_ok={false} />
        <h2 className="text-lg font-semibold py-4 pl-2">Create Your First Notifier</h2>
      </div>
      {guilds_status && activitypub_status ? (
        <NotifierPrompt />
      ) : (
        <div className="sm:flex mb-10">
          <div className="mx-auto py-4 sm:px-16 bg-gray-100 text-gray-500 rounded-lg max-w-md shadow text-center">
            You must add a Discord Server and Google Account above before continuing...
          </div>
        </div>
      )}
    </div>
  );
};

// function GoogleAccountPrompt() {
//   return (
//     <div className="sm:flex">
//       <div className="bg-gray-100 rounded-lg mx-auto max-w-md shadow">
//         <div className="w-full flex items-center justify-between p-6 space-x-6">
//           <div className="flex-1">
//             <div className="flex items-center space-x-3 justify-center">
//               <h3 className="text-gray-900 font-medium">Next, Connect a Google Account</h3>
//             </div>
//             <p className="mt-4 sm:px-10 text-gray-500">
//               To send event updates to Discord you need to link your Google Account so that you can select a calendar.
//             </p>
//             <p className="mt-4 sm:px-10 text-gray-500 text-sm">You can connect additional Google Accounts later.</p>
//           </div>
//         </div>
//         <div>
//           <div className="-mt-px flex">
//             <div className="w-0 flex-1 flex">
//               <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center pb-4 text-sm font-medium rounded-b-lg">
//                 {/* <AddGoogleCalendar text={"Connect a Google Account"} /> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function NotifierPrompt() {
  return (
    <div className="sm:flex mb-10">
      <div className="bg-gray-100 rounded-lg mx-auto max-w-md shadow">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1">
            <div className="flex items-center space-x-3 justify-center">
              <h3 className="text-gray-900 font-medium">Finally, Create a Notifier!</h3>
            </div>
            <p className="mt-4 sm:px-10 text-gray-500 text-sm">
              Pachy lets you add <strong>Notifiers</strong> which link a Google Calendar to a Discord Channel.
            </p>

            <p className="mt-4 sm:px-10 text-gray-500 text-sm">
              You can add additional notifiers later, but lets start by creating your first.
            </p>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-700">
            <div className="w-0 flex-1 flex">
              <Link
                key="add-notifier"
                to="/notifier/new"
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sky-500 hover:bg-gray-200 transition-colors font-semibold border border-gray-50 rounded-b-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                </svg>
                Add Notifier
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { WelcomeWizard };
