import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authHeader, handleResponse } from "../../_helpers";

export const fetchSubscription = createAsyncThunk("subscription/fetchSubscriptionStatus", async (arg, { getState }) => {
  let jwtToken = getState().user.jwt;

  const requestOptions = { method: "GET", headers: authHeader(jwtToken) };
  let url = new URL("/api/subscription", process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
});

export const createStripeSession = createAsyncThunk(
  "subscription/createStripeSessionStatus",
  async (priceID, { getState }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = {
      method: "GET",
      headers: authHeader(jwtToken),
    };

    let url = new URL("/api/subscription/create_stripe_session", process.env.REACT_APP_API_PATH);
    url.search = new URLSearchParams({ price_id: priceID }).toString();

    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const createStripePortal = createAsyncThunk(
  "subscription/createStripePortalStatus",
  async (arg, { getState }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = {
      method: "GET",
      headers: authHeader(jwtToken),
    };

    let url = new URL("/api/subscription/create_stripe_portal", process.env.REACT_APP_API_PATH);

    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    status: "idle",
    subscription: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscription.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.subscription == null) {
          state.subscription = {};
        } else {
          state.subscription = action.payload.subscription;
        }
      })
      .addCase(fetchSubscription.rejected, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectSubscription = (state) => state.subscription.subscription;

export default subscriptionSlice.reducer;
