import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { trackGoal } from "fathom-client";
import { LoginWithDiscord } from "../../components/LoginWithDiscord";
import { AddDiscordGuild } from "../../components/AddDiscordGuild";

const BotAddedPage = () => {
  const location = useLocation();
  const standalone = window.opener === null;

  let params = parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    delete params.code;

    if (params.error) {
      window.opener?.onError(params);
    } else {
      window.opener?.onSuccess(params);
      // Fathom: Install Bot
      trackGoal("6PQAXL2G");
    }

    if (standalone && params?.guild_id) {
      fetch(`/api/maintenance/guilds/${params.guild_id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <div className="flex-1 mt-14 px-2 max-w-xs mx-auto">
      {standalone ? <LoginPrompt error={params.error} /> : <ClosePopupPrompt error={params.error} />}
    </div>
  );
};

const LoginPrompt = ({ error }) => {
  return (
    <>
      {error ? (
        <div className="space-y-8">
          <div className="font-semibold text-lg mt-4 text-red-400 text-center">Discord Bot Not Added</div>

          <div className="leading-relaxed">
            An error occurred while trying to add this server. If this was in error, please try again.
          </div>
          <AddDiscordGuild className="flex justify-center items-center text-xl font-semibold rounded py-3 px-8 mx-auto bg-blurple hover:bg-blurple-dark transition-colors ease-in-out" />
        </div>
      ) : (
        <div className="space-y-8">
          <div className="font-semibold text-lg mt-4 text-green-500 text-center">Discord Bot Added to Server</div>
          <div className="leading-relaxed">To Link your Google Calendar please login to the web dashboard.</div>
          <LoginWithDiscord />
        </div>
      )}
    </>
  );
};

const ClosePopupPrompt = ({ error }) => {
  const onClickClose = () => {
    window.close();
  };

  return (
    <>
      {error ? (
        <div className="space-y-8">
          <div className="font-semibold text-lg mt-4 text-red-400 text-center">Discord Bot Not Added</div>
          <div className="leading-relaxed">
            An error occurred while trying to add this server. Please close this window and try again.
          </div>
          <button
            onClick={onClickClose}
            className="border rounded py-1 px-5 mx-auto block bg-gray-100 hover:bg-gray-50"
          >
            Close Window
          </button>
        </div>
      ) : (
        <div className="space-y-8">
          <div className="font-semibold text-lg mt-4 text-green-500 text-center">Discord Bot Added to Server</div>
          <div className="leading-relaxed">
            The Pachy bot has been added to your server. Please close this window to complete your setup.
          </div>
          <button
            onClick={onClickClose}
            className="border rounded py-1 px-5 mx-auto block bg-gray-100 hover:bg-gray-50"
          >
            Close Window
          </button>
        </div>
      )}
    </>
  );
};

export { BotAddedPage };
