import React, { useEffect } from "react";
import { NotifiersList } from "./NotifiersList";
import { WelcomeWizard } from "./WelcomeWizard";

import { useSelector, useDispatch } from "react-redux";
import { selectNotifiers, selectNotifiersStatus, fetchNotifiers } from "../../features/notifiers/notifiersSlice";
import { fetchGuilds } from "../../features/guilds/guildsSlice";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const notifiers = useSelector(selectNotifiers);
  const notifiersStatus = useSelector(selectNotifiersStatus);

  useEffect(() => {
    dispatch(fetchNotifiers());
    dispatch(fetchGuilds({ refresh: false }));
  }, [dispatch]);

  return (
    <div className="flex-1 w-full px-4 max-w-screen-xl mx-auto">
      {notifiersStatus === "loading" ? (
        <div className="flex justify-center mt-20">
          <svg
            className="animate-spin h-12 w-12 text-gray-50"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : notifiers.length === -1 ? (
        <WelcomeWizard />
      ) : (
        <NotifiersList notifiers={notifiers} />
      )}
    </div>
  );
};

export { DashboardPage };
