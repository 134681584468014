import { useState, useEffect, Fragment } from "react";
import { Disclosure, Listbox, Transition } from "@headlessui/react";

import { ChevronRightIcon, SelectorIcon } from "@heroicons/react/solid";
import { XCircleIcon, PlusIcon } from "@heroicons/react/outline";
import { WarningLicenseRequired } from "./WarningLicenseRequired";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StatusFilters = ({ statusFilters, setStatusFilters, statusFilterLogic, setStatusFilterLogic, licensed }) => {
  return (
    <div className="mt-4">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center text-blue-600 hover:text-blue-700 transition-colors ease-in-out">
              <div>Post Filters</div>
              {statusFilters.length > 0 ? (
                <div className="ml-2 mt-0.5 px-1 text-xs font-extrabold bg-blue-400 text-black rounded-full">
                  {statusFilters.length}
                </div>
              ) : null}
              <ChevronRightIcon className={`ml-1 mt-0.5 w-5 h-5 ${open ? "transform rotate-90" : ""}`} />
            </Disclosure.Button>

            <Transition
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Disclosure.Panel className="text-gray-500 mt-2">
                <div className="mb-1">
                  {/* TODO bekit - Re-enable if I add back the docs pages */}
                  {/* <a
                    target="_blank"
                    href="https://pachy.social/docs/notifier/creating-a-post-notifier#post-filters"
                    className="text-sky-500 hover:underline"
                  > */}
                  {/* Post filters */}
                  {/* </a>{" "} */}
                  Post filters can be used to include or remove posts which match the search string provided.
                </div>

                <Transition
                  show={statusFilters.length > 1}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <LogicDropdown statusFilterLogic={statusFilterLogic} setStatusFilterLogic={setStatusFilterLogic} />
                </Transition>

                <StatusFitlersSubForm
                  statusFilters={statusFilters}
                  setStatusFilters={setStatusFilters}
                  licensed={licensed}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

const StatusFitlersSubForm = ({ statusFilters, setStatusFilters, licensed }) => {
  // https://bapunawarsaddam.medium.com/add-and-remove-form-fields-dynamically-using-react-and-react-hooks-3b033c3c0bf5
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setShowWarning(false);
  }, [licensed, statusFilters]);

  let handleChange = (i, filter) => {
    let newFormValues = [...statusFilters];
    newFormValues[i] = filter;
    setStatusFilters(newFormValues);
  };

  let addFormFields = () => {
    if (!licensed && statusFilters.length >= 1) {
      setShowWarning(true);
      return;
    }

    setStatusFilters([...statusFilters, { action: "include", field: "content", search_string: "", valid: false }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...statusFilters];
    newFormValues.splice(i, 1);
    setStatusFilters(newFormValues);
  };

  return (
    <div>
      {statusFilters.map((element, index) => (
        <div className="" key={index}>
          <div className="py-1 flex items-center space-x-2">
            <FilterDropdown filter={element} setFilter={(f) => handleChange(index, f)} />

            <button type="button" className="button remove" onClick={() => removeFormFields(index)}>
              <XCircleIcon className="text-red-400 h-6 ml-2" />
            </button>
          </div>
        </div>
      ))}

      <div className="button-section">
        {statusFilters.length < 5 && !showWarning ? (
          <button
            className="flex items-center justify-center mt-2 ml-1 border-2 border-dashed border-gray-400 rounded-lg py-2 px-6 text-green-500 hover:text-green-400 transition-colors ease-in-out"
            type="button"
            onClick={() => addFormFields()}
          >
            <PlusIcon className="h-5 mr-2" />
            {statusFilters.length === 0 ? "Add an Post Filter" : "Add another Post Filter"}
          </button>
        ) : null}

        {!licensed && statusFilters.length >= 1 && showWarning ? (
          <WarningLicenseRequired className="mt-1" text="Maximum number of post filters reached for this server" />
        ) : null}
      </div>
    </div>
  );
};

function FilterDropdown({ filter, setFilter }) {
  const actions = {
    include: "Include",
    remove: "Remove",
  };

  const fields = {
    content: "Content",
  };

  useEffect(() => {
    // Makes sure field is defined (this was added in a later iteration of filters)
    if (!filter?.field || filter.field === "") {
      let f = { ...filter, field: "content" };
      f.valid = validate(f);
      setFilter(f);
    }
  }, [filter, setFilter]);

  let onActionChange = (newAction) => {
    let f = { ...filter, action: newAction };
    f.valid = validate(f);

    setFilter(f);
  };

  let onFieldChange = (newField) => {
    let f = { ...filter, field: newField };
    f.valid = validate(f);

    setFilter(f);
  };

  let onSearchStringChange = (e) => {
    let f = { ...filter, search_string: e.target.value };
    f.valid = validate(f);

    setFilter(f);
  };

  let validate = (f) => {
    return f.search_string.length > 0;
  };
  const isValid = validate(filter);

  return (
    <div className="text-black flex flex-wrap items-center">
      {/* Actions */}
      <Listbox value={filter.action} onChange={onActionChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-32 py-2 pl-3 pr-10 text-left bg-gray-50 border border-gray-200 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{actions[filter.action]}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-statuses-none">
              <SelectorIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-50 border border-gray-100 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {Object.keys(actions).map((actionName, actIdx) => (
                <Listbox.Option
                  key={actIdx}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                            cursor-default select-none relative`
                  }
                  value={actionName}
                >
                  {({ selected, active }) => (
                    <span
                      className={`${selected ? "font-semibold" : "font-normal"} ${
                        active ? "bg-indigo-50" : null
                      } block truncate py-2 px-4`}
                    >
                      {actions[actionName]}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <div className="hidden sm:block mx-2">post content matching</div>

      <Listbox value={filter.field} onChange={onFieldChange}>
        {/* Current hiding the selection box until we have multiple filter types */}
        <div className="relative mt-1 hidden">
          <Listbox.Button className="relative w-32 py-2 pl-3 pr-10 text-left bg-gray-50 border border-gray-200 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{fields[filter.field]}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-statuses-none">
              <SelectorIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-gray-50 border border-gray-100 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {Object.keys(fields).map((fieldName, fieldIdx) => (
                <Listbox.Option
                  key={fieldIdx}
                  className={({ active }) =>
                    `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                            cursor-default select-none relative`
                  }
                  value={fieldName}
                >
                  {({ selected, active }) => (
                    <span
                      className={`${selected ? "font-semibold" : "font-normal"} ${
                        active ? "bg-indigo-50" : null
                      } block truncate py-2 px-4`}
                    >
                      {fields[fieldName]}
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      {/* Putting all text in the above div while the filter type is unused */}
      {/* <div className="hidden sm:block mx-2">matching</div> */}

      {/* Search String */}
      <input
        type="text"
        value={filter.search_string}
        onChange={onSearchStringChange}
        className={`${
          isValid ? null : "border-red-400"
        } bg-gray-50 mt-1 mr-2 py-2 pl-3 border border-gray-200 rounded-lg shadow-md sm:text-sm`}
        placeholder={"Search Term"}
      />
    </div>
  );
}

function LogicDropdown({ statusFilterLogic, setStatusFilterLogic }) {
  const actions = {
    or: "Match Any Filter (Logical OR)",
    and: "Match All Filters (Logical AND)",
  };

  return (
    <ul className="my-2 divide-y border-b divide-gray-700 border-gray-300">
      <li className="py-4 sm:grid grid-cols-3 items-center justify-between">
        <div className="col-span-2 flex flex-col">
          <p className="text-sm font-medium text-gray-900" passive="true">
            Filter Combination Logic
          </p>
          <div className="text-sm text-gray-500">Select the logic used when multiple filters are combined.</div>
        </div>
        <Listbox value={statusFilterLogic} onChange={setStatusFilterLogic}>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-gray-50 text-black relative w-full border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{actions[statusFilterLogic] || "Select a Combination Logic..."}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-statuses-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-50 border border-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {Object.keys(actions).map((actionName, actIdx) => (
                  <Listbox.Option
                    key={actIdx}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-50" : "",
                        "cursor-default select-none relative py-2 px-3 text-gray-900"
                      )
                    }
                    value={actionName}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex justify-between">
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                            {actions[actionName]}
                          </span>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </li>
    </ul>
  );
}

export { StatusFilters };
