import React, { useEffect } from "react";
import { BuildDiscordBotAuthURL } from "../../components/AddDiscordGuild";

const AddDiscordBotPage = () => {
  const discordBotAuthURL = BuildDiscordBotAuthURL();

  useEffect(() => {
    setTimeout(function () {
      window.location.href = discordBotAuthURL;
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="flex-1 mt-14 px-2 max-w-xs mx-auto"></div>;
};

export { AddDiscordBotPage };
