import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import nightwind from "nightwind/helper";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";

import store from "./store/store";
import { Provider } from "react-redux";

import "./common/index.scss";

Sentry.init({
  dsn: "https://4210972cdaa04d4a93c1c6d57f6571fb@o993148.ingest.sentry.io/4504617513844736",
  environment: process.env.NODE_ENV || "production",
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.REACT_APP_SENTRY_VERSION,
  enabled: process.env.NODE_ENV === "development" ? false : true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <script>{nightwind.init()}</script>
      </Helmet>

      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
