import { authHeader, handleResponse } from "../_helpers";

function createNotifier(notifier) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(notifier),
  };

  let url = new URL("/api/notifiers", process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
}

export const notifierService = {
  createNotifier,
};
