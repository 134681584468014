import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { BookmarkIcon, XIcon } from "@heroicons/react/solid";

import { selectInstalledGuilds, fetchGuilds, licenseGuild, unlicenseGuild } from "../../features/guilds/guildsSlice";
import { GuildAvatarImage } from "../../components/GuildAvatarImage";
import { fetchSubscription, createStripePortal } from "../../features/subscription/subscriptionSlice";
import { toast } from "react-toastify";

const SubscriptionStatusPanel = ({ subscription }) => {
  const dispatch = useDispatch();
  const guilds = useSelector(selectInstalledGuilds);

  useEffect(() => {
    dispatch(fetchGuilds({ refresh: false }));
  }, [dispatch]);

  const expiration_date = new Date(subscription.expiration_datetime);

  const stripeCreatePortal = () => {
    dispatch(createStripePortal()).then((result) => {
      window.location.href = result.payload.redirect;
    });
  };

  return (
    <div className="flex-1 w-full flex flex-col justify-center items-center px-4 max-w-screen-xl mx-auto">
      <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5 min-w-full">
        <div className="mx-auto lg:max-w-5xl">
          <div className="rounded-lg bg-gray-50 px-6 py-6 lg:flex lg:items-center max-w-screen-md mx-auto">
            <div className="w-full">
              <div className="flex justify-between">
                <div>
                  <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-yellow-200 text-yellow-900">
                    {subscription.name}
                  </h3>
                  <div className="text-sm font-semibold ml-1 mt-0.5 text-gray-500">
                    Subscribed till: {expiration_date.toLocaleDateString()}
                  </div>
                </div>
                <div>
                  <div className="rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                    <button
                      onClick={stripeCreatePortal}
                      className="flex items-center justify-center px-4 py-2 sm:px-5 sm:py-3 border border-transparent text-sm sm:text-base font-semibold rounded-md text-gray-900 bg-indigo-400 hover:bg-indigo-500"
                    >
                      Visit Billing Portal
                    </button>
                    <div className="mt-2 text-center text-sm sm:text-base">
                      {subscription.licenses ? subscription.licenses.length : 0} of {subscription.license_count}{" "}
                      licenses used
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:mt-5 text-sm sm:text-lg text-gray-900">
                Thank you for supporting our work on Pachy Bot!
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Guilds Table */}
      <div className="flex flex-col mt-6">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-w-screen-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Discord Server
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Notifiers
                    </th>
                    <th
                      scope="col"
                      className="hidden sm:table-cell px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Licensed
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-100 divide-y divide-gray-200">
                  {guilds.map((guild) => (
                    <GuildTableRow key={guild.id} guild={guild} licenses={subscription.licenses} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GuildTableRow = ({ guild, licenses }) => {
  const dispatch = useDispatch();
  const isManager = guild.permissions & (1 << 5);
  const isMyLicense = licenses?.find((license) => license.guild_id === guild.id);

  const onLicenseGuild = () => {
    let licensePromise = dispatch(licenseGuild(guild.id)).then((result) => {
      if (result.meta.requestStatus !== "rejected") {
        dispatch(fetchSubscription());
        dispatch(fetchGuilds({ refresh: false }));
      } else {
        return Promise.reject(result.error);
      }
    });

    toast.promise(licensePromise, {
      pending: "Changing Server License...",
      success: "Server License Updated!",
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };
  const onUnlicenseGuild = () => {
    if (
      window.confirm("Are you sure you wish to unlicense this server? Doing so will disable all associated notifiers.")
    ) {
      let licensePromise = dispatch(unlicenseGuild(guild.id)).then((result) => {
        if (result.meta.requestStatus !== "rejected") {
          dispatch(fetchSubscription());
          dispatch(fetchGuilds({ refresh: false }));
        } else {
          return Promise.reject(result.error);
        }
      });

      toast.promise(licensePromise, {
        pending: "Changing Server License...",
        success: "Server License Updated!",
        error: {
          render({ data }) {
            return data.message;
          },
        },
      });
    }
  };

  return (
    <tr key={guild.id}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <GuildAvatarImage guild={guild} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-semibold text-gray-900">{guild.name}</div>
            {guild.owner ? (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">
                Owner
              </span>
            ) : isManager ? (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                Manager
              </span>
            ) : (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-200 rounded-full">
                Member
              </span>
            )}
          </div>
        </div>
      </td>
      <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
        <div className="text-lg font-bold text-gray-800">{guild.active_notifiers_count}</div>
      </td>
      <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold">
          {guild.license?.licensed ? (
            <BookmarkIcon
              className={`${isMyLicense ? "text-yellow-500" : "text-blue-500"} h-8 w-8`}
              aria-hidden="true"
            />
          ) : (
            <XIcon className="h-8 w-8 text-red-300" aria-hidden="true" />
          )}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
        <button
          onClick={guild.license?.licensed ? onUnlicenseGuild : onLicenseGuild}
          disabled={guild.license?.licensed && !isMyLicense}
          className={`${
            !guild.license?.licensed ? "bg-green-300 hover:bg-green-200" : "bg-red-300 hover:bg-red-200"
          } text-black hover:text-indigo-900 rounded-lg shadow-lg py-2 px-4 transition-colors ease-in-out disabled:bg-gray-300`}
        >
          {!guild.license?.licensed ? "Add License" : "Remove License"}
        </button>
      </td>
    </tr>
  );
};

export { SubscriptionStatusPanel };
