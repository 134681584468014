import { configureStore } from "@reduxjs/toolkit";
import userReducer, { userSlice } from "../features/user/userSlice";
import guildsReducer from "../features/guilds/guildsSlice";
// import googleReducer from "../features/google/googleSlice";
import notifiersReducer from "../features/notifiers/notifiersSlice";
import subscriptionReducer from "../features/subscription/subscriptionSlice";

const authMiddleware = (store) => (next) => (action) => {
  next(action);

  if (userSlice.actions.setJWT.match(action)) {
    // Note: localStorage expects a string
    localStorage.setItem("jwt", store.getState().user.jwt);
  } else if (userSlice.actions.logoutUser.match(action)) {
    localStorage.removeItem("jwt");
  }
  return;
};

export default configureStore({
  reducer: {
    user: userReducer,
    guilds: guildsReducer,
    // google: googleReducer,
    notifiers: notifiersReducer,
    subscription: subscriptionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
});
