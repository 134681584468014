import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, BookmarkIcon } from "@heroicons/react/solid";

import { AddDiscordGuild } from "../../../components/AddDiscordGuild";

const SelectListGuild = ({ guilds, selected, setActiveGuild }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const onSelectionChange = (guild) => {
    setActiveGuild(guild);
  };

  return (
    <div>
      <Listbox value={selected} onChange={onSelectionChange}>
        <div className="mt-1 relative">
          <Listbox.Button className="bg-gray-50 text-black relative w-full border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selected?.name || "Select a Discord Server..."}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-50 border border-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {guilds.map((guild) => (
                <Listbox.Option
                  key={guild.id}
                  className={({ active }) =>
                    classNames(
                      active ? "text-whitex bg-indigo-50" : "text-gray-900",
                      "cursor-default select-none relative py-2 px-3"
                    )
                  }
                  value={guild}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex justify-between">
                        <span
                          className={classNames(
                            guild.status === "ok" ? "" : "text-red-400",
                            classNames(selected ? "font-semibold" : "font-normal", "block truncate")
                          )}
                        >
                          {guild.name}
                        </span>

                        <div className="flex">
                          {guild.status !== "ok" ? (
                            <button className="flex items-center pr-2">Setup Needed</button>
                          ) : null}

                          {guild.license.licensed ? (
                            <div className="flex items-center pr-2 text-blue-400 text-xs font-bold -mx-12">
                              <BookmarkIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                            </div>
                          ) : null}

                          {selected ? (
                            <span className="absolute text-green-500 inset-y-0 right-0 flex items-center pr-4">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : (
                            <CheckIcon className="h-5 w-5 invisible" aria-hidden="true" />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              ))}
              <AddDiscordGuild popup={true} />
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export { SelectListGuild };
