import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { selectSubscription, fetchSubscription } from "../../features/subscription/subscriptionSlice";
import { selectUser } from "../../features/user/userSlice";
import { SubscriptionBlockedPanel } from "./SubscriptionBlockedPanel";

import { SubscriptionLevelsPanel } from "./SubscriptionLevelsPanel";
import { SubscriptionStatusPanel } from "./SubscriptionStatusPanel";

const PremiumSalePage = (props) => {
  const dispatch = useDispatch();
  const subscription = useSelector(selectSubscription);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  return (
    <div className="flex-1 w-full px-4 py-4 max-w-screen-xl mx-auto">
      {user?.blocked_payments ? (
        <SubscriptionBlockedPanel blockedReason={user.blocked_reason} />
      ) : subscription?.license_count > 0 ? (
        <SubscriptionStatusPanel subscription={subscription} />
      ) : (
        <SubscriptionLevelsPanel />
      )}
    </div>
  );
};

export { PremiumSalePage };
