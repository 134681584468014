import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FollowedAccounts } from "./components/FollowedAccounts";
import { ChannelIcon, SelectListChannel } from "./components/SelectListChannel";
import { SelectListGuild } from "./components/SelectListGuild";
import { WarningLicenseRequired } from "./components/WarningLicenseRequired";

// import * as Sentry from "@sentry/react";

import { Switch, Tab } from "@headlessui/react";

import { useSelector, useDispatch } from "react-redux";
// import { selectUser } from "../../features/user/userSlice";
import {
  selectActiveGuild,
  selectManagedGuildsInstalled,
  selectGuildChannels,
  selectGuildRoles,
  fetchGuilds,
  fetchGuildChannels,
  fetchGuildRoles,
} from "../../features/guilds/guildsSlice";
import {
  selectNotifierById,
  addNotifier,
  checkNotifierPermissions,
  deleteNotifier,
  fetchNotifiers,
} from "../../features/notifiers/notifiersSlice";
import { trackGoal } from "fathom-client";
import { GuildAvatarImage } from "../../components/GuildAvatarImage";
import { ErrorMessage } from "../../components/ErrorMessage";

import { PostNotificationsSettingsPanel } from "./PostNotificationsSettingsPanel";
// import { SettingsPanel } from "./SettingsPanel";
import { StatusFilters } from "./components/StatusFilters";
import { AnnotationIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EditNotifierPage = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isNew = typeof id === "undefined";
  const notifier = useSelector((state) => selectNotifierById(state, id));
  const [savingNotifier, setSavingNotifier] = useState(false);

  const [missingPermissions, setMissingPermissions] = useState([]);

  // Discord variables
  const guilds = useSelector(selectManagedGuildsInstalled);
  const activeGuild = useSelector(selectActiveGuild);
  const guildChannels = useSelector(selectGuildChannels);
  const guildRoles = useSelector(selectGuildRoles);
  const [activeChannel, setActiveChannel] = useState();
  const [channelType, setChannelType] = useState(0);
  const [channelAutoPublish, setChannelAutoPublish] = useState(false);

  const setActiveGuild = (guild) => {
    let fetchChannelsPromise = dispatch(fetchGuildChannels(guild.id)).then((result) => {
      if (result.meta.requestStatus === "rejected") {
        return Promise.reject(result.error);
      }
    });

    toast.promise(fetchChannelsPromise, {
      error: {
        render({ data }) {
          return `${data.message}. Please verify PachyBot's permissions to this server.`;
        },
      },
    });

    let fetchRolesPromise = dispatch(fetchGuildRoles(guild.id)).then((result) => {
      if (result.meta.requestStatus === "rejected") {
        return Promise.reject(result.error);
      }
    });

    toast.promise(fetchRolesPromise, {
      error: {
        render({ data }) {
          return `${data.message}. Please verify PachyBot's permissions to this server.`;
        },
      },
    });

    // // Check for a preferred locale
    if (guild.preferred_locale?.length > 0 && localeCode === "") {
      setLocaleCode(guild.preferred_locale);
    }
  };

  // Notifier Generics
  const [notifierName, setNotifierName] = useState("");
  const [notifierEnabled, setNotifierEnabled] = useState(true);

  // Status Settings
  const [followedAccounts, setFollowedAccounts] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [statusFilterLogic, setStatusFilterLogic] = useState("or");

  // Notifier Reminder specific variables
  const [mentionRole, setMentionRole] = useState(null);
  const [notifyOnReplies, setNotifyOnReplies] = useState(true);
  const [notifyOnBoosts, setNotifyOnBoosts] = useState(true);
  const [editHandlingType, setEditHandlingType] = useState(0);
  const [enableRemoveDeleted, setEnableRemoveDeleted] = useState(true);
  // Notifier Settings
  const [localeCode, setLocaleCode] = useState("");

  // Initial Setup, regardless of state
  useEffect(() => {
    dispatch(fetchGuilds({ refresh: false }));
    dispatch(fetchNotifiers());
  }, [dispatch]);

  // If a notifier is being edited, start retrieving data and setup checkboxes
  useEffect(() => {
    if (typeof notifier === "undefined" || notifier.lookup_id !== id) return;

    setNotifierName(notifier.name);
    setFollowedAccounts(notifier.followed_accounts);

    // console.log("Setup Notifier:", notifier);
    dispatch(fetchGuildChannels(notifier.guild.id));
    dispatch(fetchGuildRoles(notifier.guild.id));

    setChannelType(notifier.channel_type);
    setChannelAutoPublish(notifier.channel_auto_publish);

    setNotifierEnabled(notifier.enabled);

    setStatusFilters(notifier.status_filters || []);
    setStatusFilterLogic(notifier.status_filter_logic || "or");

    setMentionRole(notifier.mention_role);

    setNotifyOnReplies(notifier.notify_on_replies);
    setNotifyOnBoosts(notifier.notify_on_boosts);
    setEditHandlingType(notifier.edit_handling_type);
    setEnableRemoveDeleted(notifier.enable_remove_deleted);

    setLocaleCode(notifier.locale_code);
  }, [id, dispatch, notifier]);

  // If a notifier is being edited, set the appropriate channel
  useEffect(() => {
    if (typeof notifier === "undefined" || notifier.lookup_id !== id) return;

    let channel = guildChannels.find((chan) => chan.id === notifier.channel.id);
    setActiveChannel(channel);
  }, [notifier, id, guildChannels]);

  useEffect(() => {
    if (activeChannel?.type) {
      setChannelType(activeChannel.type);
    }
  }, [activeChannel]);

  // Track if the guild is licensed
  const [licensed, setLicensed] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLicensed(activeGuild?.license.licensed);
  });

  // Check Validity of the Form
  const [valid, setValid] = useState(false);
  const [connectionValid, setConnectionValid] = useState(false);
  const [connectionLicenseValid, setConnectionLicenseValid] = useState(false);
  const [statusFiltersValid, setStatusFiltersValid] = useState(false);
  const [statusFiltersCountValid, setStatusFiltersCountValid] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setConnectionValid(followedAccounts.length > 0 && (activeChannel || !isNew));
    setConnectionLicenseValid(followedAccounts.length < 2 || licensed);
    setStatusFiltersValid(statusFilters.findIndex((filter) => filter["valid"] !== undefined && !filter.valid) === -1);
    setStatusFiltersCountValid(statusFilters.length < 2 || licensed);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setValid(connectionValid && connectionLicenseValid && statusFiltersValid && statusFiltersCountValid);
  });

  useEffect(() => {
    setActiveChannel(null);
  }, [activeGuild]);

  const postNotifier = () => {
    if (savingNotifier) {
      return;
    }
    setSavingNotifier(true);

    var newNotifier = {};

    if (isNew) {
      newNotifier = {
        guild: { id: activeGuild.id, name: activeGuild.name },
        channel: { id: activeChannel.id, name: activeChannel.name },
      };
    } else {
      newNotifier = JSON.parse(JSON.stringify(notifier));
      if (activeGuild?.name) newNotifier.guild.name = activeGuild.name;
      if (activeChannel?.name) newNotifier.channel.name = activeChannel.name;
    }

    // Copy over values which can change on both new and updated flows
    newNotifier.name = notifierName;
    newNotifier.followed_accounts = followedAccounts;
    newNotifier.enabled = notifierEnabled;

    newNotifier.channel_type = channelType;
    newNotifier.channel_auto_publish = channelAutoPublish;

    newNotifier.status_filters = statusFilters;
    newNotifier.status_filter_logic = statusFilterLogic;

    newNotifier.mention_role = mentionRole;

    newNotifier.notify_on_replies = notifyOnReplies;
    newNotifier.notify_on_boosts = notifyOnBoosts;
    newNotifier.edit_handling_type = editHandlingType;
    newNotifier.enable_remove_deleted = enableRemoveDeleted;

    // Advanced Settings
    newNotifier.locale_code = localeCode;

    let checkPermissionsPromise = dispatch(checkNotifierPermissions({ notifier: newNotifier })).then((result) => {
      if (result.meta.requestStatus === "rejected") {
        setSavingNotifier(false);
        return Promise.reject(result.error);
      } else {
        if (result.payload.missing?.length > 0) {
          // Fathom: Save Notifier Error
          trackGoal("T47D3BZK");
          setMissingPermissions(result.payload.missing);
          setSavingNotifier(false);
          return Promise.reject(result.payload);
        }

        // Permission are good
        setMissingPermissions([]);
        submitNotifier({ notifier: newNotifier });
      }
    });

    // https://fkhadra.github.io/react-toastify/promise
    toast.promise(checkPermissionsPromise, {
      pending: "Checking permissions...",
      success: "Permissions are good!",
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  const submitNotifier = (notifierData) => {
    let savePromise = dispatch(addNotifier(notifierData)).then((result) => {
      if (result.meta.requestStatus !== "rejected") {
        // Fathom: Save Notifier
        trackGoal("9RA72JQQ");
        props.history.push("/dashboard");
      } else {
        setSavingNotifier(false);
        return Promise.reject(result.error);
      }
    });

    toast.promise(savePromise, {
      pending: "Saving notifier...",
      success: "Notifier Saved!",
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  const handleDeleteNotifier = () => {
    if (window.confirm("Are you sure you wish to delete this notifier?")) {
      let deletePromise = dispatch(deleteNotifier(notifier)).then((result) => {
        if (result.meta.requestStatus !== "rejected") {
          props.history.push("/dashboard");
        } else {
          return Promise.reject(result.error);
        }
      });

      toast.promise(deletePromise, {
        pending: "Deleting notifier...",
        success: "Notifier Deleted!",
        error: {
          render({ data }) {
            return data.message;
          },
        },
      });
    }
  };

  let license_required = !licensed && activeGuild?.active_notifiers_count > 0;

  const settingsTabs = [
    { name: "Notifications", icon: AnnotationIcon, beta: false },
    // { name: "Settings", icon: CogIcon, beta: false },
  ];

  return (
    <div className="max-w-screen-md mx-auto">
      <div className="font-semibold text-2xl mt-6 mx-1">
        <input
          type="text"
          placeholder="Notifier Name"
          value={notifierName}
          onChange={(e) => setNotifierName(e.target.value)}
          className="w-full pl-3 pr-10 py-2 bg-gray-50 text-black rounded-xl focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      <div className="relative">
        <div className="pb-12 bg-gray-100 rounded-xl my-4 mx-1 px-4 py-4 sm:px-8 sm:py-6">
          <h2 className="text-2xl leading-6 font-medium text-gray-900 mb-8">Fediverse Connection</h2>

          <div className="absolute right-8 top-0.5">
            <Switch.Group as="div" className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label
                  as="div"
                  className="invisible sm:visible text-sm font-semibold text-gray-800 tracking-wide"
                  passive="true"
                >
                  Enabled
                </Switch.Label>
              </div>
              <Switch
                checked={notifierEnabled}
                onChange={setNotifierEnabled}
                className={classNames(
                  notifierEnabled ? "bg-green-400" : "bg-gray-400",
                  "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ring-offset-gray-700"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    notifierEnabled ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          <div className="sm:flex space-y-12 sm:space-y-0">
            <FollowedAccounts followedAccounts={followedAccounts} setFollowedAccounts={setFollowedAccounts} />

            <div className="flex-1 sm:ml-4 sm:w-72 md:w-80">
              <h2 className="text-blue-400 font-semibold pb-1">Discord Channel:</h2>
              {isNew ? (
                <div className="space-y-2">
                  <SelectListGuild guilds={guilds} selected={activeGuild} setActiveGuild={setActiveGuild} />
                  {license_required ? (
                    <WarningLicenseRequired text="Maximum number of notifiers reached for this server" />
                  ) : (
                    <SelectListChannel
                      guildChannels={guildChannels}
                      selected={activeChannel}
                      setActiveChannel={setActiveChannel}
                    />
                  )}
                </div>
              ) : (
                <div className="flex">
                  <GuildAvatarImage guild={activeGuild} name={activeGuild?.name || notifier?.guild.name} />
                  <div className="ml-2">
                    <div className="font-medium text-gray-900 truncate max-w-[16rem]">
                      {activeGuild?.name || notifier?.guild.name}
                    </div>
                    <div className="inline-block">
                      <div className="text-sm text-gray-700 flex bg-white rounded-full pl-2 pr-3 py-0.5">
                        <ChannelIcon channelType={channelType} className="w-5 h-5 text-gray-500 mr-1" />
                        <div className="max-w-[12rem] truncate">{activeChannel?.name || notifier?.channel.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {channelType === 5 ? (
                <div>
                  <Switch.Group as="div" className="py-4 flex items-center justify-between">
                    <div className="flex flex-col">
                      <Switch.Label
                        as="div"
                        className="invisible sm:visible text-sm font-semibold text-gray-800 tracking-wide"
                        passive="true"
                      >
                        Auto Publish
                      </Switch.Label>
                      <Switch.Description className="text-sm text-gray-500">
                        Publish to announcement channel
                      </Switch.Description>
                    </div>
                    <Switch
                      checked={channelAutoPublish}
                      onChange={setChannelAutoPublish}
                      className={classNames(
                        channelAutoPublish ? "bg-green-400" : "bg-gray-400",
                        "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ring-offset-gray-700"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          channelAutoPublish ? "translate-x-5" : "translate-x-0",
                          "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
              ) : null}
            </div>
          </div>

          <StatusFilters
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            statusFilterLogic={statusFilterLogic}
            setStatusFilterLogic={setStatusFilterLogic}
            licensed={licensed}
          />
        </div>

        {/* Settings Panel */}
        <div className="bg-gray-100 rounded-xl my-4 mx-1 mb-8 pb-8">
          <Tab.Group>
            <Tab.List className={"pt-2 px-8 border-b border-gray-300 flex justify-evenly space-x-4"}>
              {settingsTabs.map((item) => {
                return item.beta ? null : (
                  <Tab
                    key={item.name}
                    className={({ selected }) =>
                      selected ? "border-sky-400 border-b-2 text-sky-500 pb-1" : "hover:text-blue-700 pb-1"
                    }
                  >
                    <div className="flex flex-col sm:flex-row items-center ">
                      <item.icon className="h-7 w-7 sm:h-6 sm:w-6 sm:mr-1 mb-0.5 sm:mb-0" />
                      <div className="text-xs sm:text-base font-semibold whitespace-nowrap">{item.name}</div>
                    </div>
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <PostNotificationsSettingsPanel
                  activeGuild={activeGuild}
                  guildRoles={guildRoles}
                  mentionRole={mentionRole}
                  setMentionRole={setMentionRole}
                  notifyOnReplies={notifyOnReplies}
                  setNotifyOnReplies={setNotifyOnReplies}
                  notifyOnBoosts={notifyOnBoosts}
                  setNotifyOnBoosts={setNotifyOnBoosts}
                  selectedEditHandlingType={editHandlingType}
                  setSelectedEditHandlingType={setEditHandlingType}
                  enableRemoveDeleted={enableRemoveDeleted}
                  setEnableRemoveDeleted={setEnableRemoveDeleted}
                  followedAccounts={followedAccounts}
                  setFollowedAccounts={setFollowedAccounts}
                />
              </Tab.Panel>
              {/* <Tab.Panel>
                <SettingsPanel
                  selectedLinkType={statusLinkType}
                  setSelectedLinkType={setStatusLinkType}
                  localeCode={localeCode}
                  setLocaleCode={setLocaleCode}
                  frontMatter={frontMatter}
                  setFrontMatter={setFrontMatter}
                />
              </Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>

          {/* Validation Errors */}
          <ul className="mx-8 sm:mx-10 mb-4 space-y-0.5 list-outside list-disc">
            <ErrorMessage
              valid={connectionValid}
              message={"Please select at least one Followed Account and a Discord Channel."}
            />
            <ErrorMessage valid={statusFiltersValid} message={`One of your Post Filters has an error.`} />
            <ErrorMessage valid={statusFiltersCountValid} message={`This notifier has too many post filters.`} />
            <ErrorMessage
              valid={connectionLicenseValid}
              message={"An unlicensed server can not follow multiple accounts."}
            />

            <ErrorMessage
              valid={missingPermissions.length === 0}
              message={
                <>
                  Required server permissions for the bot are{" "}
                  <a
                    className="text-sky-500 hover:text-sky-600 underline"
                    href="https://pachy.social/docs/discord/permissions#channel-overwrites"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    missing
                  </a>
                  :
                </>
              }
              items={missingPermissions}
            />
          </ul>

          {/* Command Buttons */}
          <div className="flex justify-end px-4 sm:px-8">
            {!isNew ? (
              <button
                className="mr-4 rounded bg-red-300 hover:bg-red-400 transition-colors shadow-lg px-2 py-1"
                onClick={handleDeleteNotifier}
              >
                Delete
              </button>
            ) : null}

            {savingNotifier ? (
              <button
                type="button"
                className="inline-flex items-center px-4 py-1 font-bold leading-6 shadow rounded-md text-black bg-green-300 hover:bg-green-200 transition ease-in-out duration-150 cursor-not-allowed"
                disabled=""
              >
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </button>
            ) : (
              <button
                className="rounded bg-green-300 hover:bg-green-200 transition-colors ease-in-out shadow-lg px-2 py-1 disabled:bg-gray-300"
                onClick={postNotifier}
                disabled={!valid}
              >
                {isNew ? "Create Notifier" : "Save Notifier"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditNotifierPage };
