import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserLoggedIn } from "../features/user/userSlice";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const userLoggedIn = useSelector(selectUserLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userLoggedIn) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
};
