function GuildAvatarImage({ guild, name }) {
  if (guild?.icon) {
    return (
      <img
        alt={`${guild.name} icon`}
        className="w-10 h-10 rounded-full flex-shrink-0"
        src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png` : ""}
      />
    );
  } else {
    // Placeholder guild "image"
    var guildName = guild?.name || name || "";
    var acronym = guildName
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
      .substring(0, 4);

    return (
      <div className="h-10 w-10 rounded-full bg-white flex justify-center items-center text-sm font-bold">
        {acronym}
      </div>
    );
  }
}

export { GuildAvatarImage };
