// import { EventRemindersSubForm } from "./components/EventRemindersSubForm";

import { Listbox, Switch, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { SelectEditHandlingType } from "./components/SelectEditHandlingType";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PostNotificationsSettingsPanel = ({
  // activeGuild,
  guildRoles,
  mentionRole,
  setMentionRole,
  notifyOnReplies,
  setNotifyOnReplies,
  notifyOnBoosts,
  setNotifyOnBoosts,
  selectedEditHandlingType,
  setSelectedEditHandlingType,
  enableRemoveDeleted,
  setEnableRemoveDeleted,
}) => {
  return (
    <div>
      <div className="py-10 px-4 sm:px-8">
        <div className="pb-1">
          <h2 className="text-2xl leading-6 font-medium text-gray-900">Post Notifications</h2>
          <div className="pt-0.5 font-medium text-gray-600">
            Send notification messages to the selected channel when a followed account posts.
          </div>
        </div>

        <ul className="my-2 divide-y border-b divide-gray-700 border-gray-300">
          <li className="py-4 sm:grid grid-cols-3 items-center justify-between">
            <div className="col-span-2 flex flex-col">
              <p className="text-sm font-medium text-gray-900" passive="true">
                Mention a role
              </p>
              <div className="text-sm text-gray-500">Select a role to mention when a notification is sent.</div>
            </div>
            <SelectGuildRole roles={guildRoles} selected={mentionRole} onSelectionChange={setMentionRole} />
          </li>
          <Switch.Group as="li" className="py-4 flex items-center justify-between">
            <div className="flex flex-col">
              <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive="true">
                Notify on replies
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-500">
                {notifyOnReplies
                  ? "Send a new channel message when a followed account posts a reply"
                  : "Ignore replies posted by followed accounts"}
              </Switch.Description>
            </div>
            <Switch
              checked={notifyOnReplies}
              onChange={setNotifyOnReplies}
              className={classNames(
                notifyOnReplies ? "bg-green-400" : "bg-gray-400",
                "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ring-offset-gray-700"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  notifyOnReplies ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
          </Switch.Group>
          <Switch.Group as="li" className="py-4 flex items-center justify-between">
            <div className="flex flex-col">
              <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive="true">
                Notify on boosts
              </Switch.Label>
              <Switch.Description className="text-sm text-gray-500">
                {notifyOnBoosts
                  ? "Send a new channel message when a followed account boosts a post"
                  : "Ignore boosts by followed accounts"}
              </Switch.Description>
            </div>
            <Switch
              checked={notifyOnBoosts}
              onChange={setNotifyOnBoosts}
              className={classNames(
                notifyOnBoosts ? "bg-green-400" : "bg-gray-400",
                "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ring-offset-gray-700"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  notifyOnBoosts ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
          </Switch.Group>
        </ul>
      </div>
      {/* Notification Triggers Section */}
      <div className="pb-10 px-4 sm:px-8">
        <div className="">
          <div>
            <h2 className="text-2xl leading-6 font-medium text-gray-900">Updated Post Options</h2>
            <div className="pt-0.5 font-medium text-gray-600">
              Settings for how to handle posts edited or deleted by followed accounts
            </div>
          </div>
          <ul className="mt-2 divide-y divide-gray-600">
            <li className="py-4 sm:grid grid-cols-3 items-center justify-between">
              <div className="col-span-2 flex flex-col">
                <p className="text-sm font-medium text-gray-900" passive="true">
                  Edit Handling
                </p>
                <div className="text-sm text-gray-500">
                  How to handle posts that have been edited by a followed account
                </div>
              </div>
              <SelectEditHandlingType
                selectedID={selectedEditHandlingType}
                onSelectionIDChange={setSelectedEditHandlingType}
              />
            </li>
            <Switch.Group as="li" className="py-4 flex items-center justify-between">
              <div className="flex flex-col">
                <Switch.Label as="p" className="text-sm font-medium text-gray-900" passive="true">
                  Remove deleted posts
                </Switch.Label>
                <Switch.Description className="text-sm text-gray-500">
                  {enableRemoveDeleted
                    ? "Remove posts that have been deleted by followed accounts"
                    : "Retain posts that have been deleted by followed accounts"}
                </Switch.Description>
              </div>
              <Switch
                checked={enableRemoveDeleted}
                onChange={setEnableRemoveDeleted}
                className={classNames(
                  enableRemoveDeleted ? "bg-green-400" : "bg-gray-400",
                  "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ring-offset-gray-700"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enableRemoveDeleted ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>
          </ul>
        </div>
      </div>
    </div>
  );
};

const SelectGuildRole = ({ roles, selected, onSelectionChange }) => {
  const [fullRoles, setFullRoles] = useState([]);
  useEffect(() => {
    const disabledOption = { id: "0", name: "No Mention" };
    setFullRoles([disabledOption, ...roles]);

    if (roles.length > 0) {
      const currentRole = roles.find((role) => role.id === selected?.id) || disabledOption;
      onSelectionChange(currentRole);
    }
  }, [onSelectionChange, roles, selected?.id]);

  return (
    <Listbox value={selected} onChange={onSelectionChange}>
      <div className="mt-1 relative">
        <Listbox.Button className="bg-gray-50 text-black relative w-full border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <span className="block truncate">{selected?.name || "Select a Discord Role..."}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-50 border border-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {fullRoles.map((role) => (
              <Listbox.Option
                key={role.id}
                className={({ active }) =>
                  classNames(
                    active ? "text-whitex bg-indigo-50" : "text-gray-900",
                    "cursor-default select-none relative py-2 px-3"
                  )
                }
                value={role}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex justify-between">
                      <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                        {role.name}
                      </span>

                      <div className="flex">
                        {selected ? (
                          <span className="absolute text-green-500 inset-y-0 right-0 flex items-center pr-4">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : (
                          <CheckIcon className="h-5 w-5 invisible" aria-hidden="true" />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export { PostNotificationsSettingsPanel };
