import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authHeader, handleResponse } from "../../_helpers";

export const fetchActivityPubAccount = createAsyncThunk(
  "activityPub/fetchAccount",
  async (accountName, { getState }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = { method: "GET", headers: authHeader(jwtToken) };

    let url = new URL(`/api/activitypub/find_account?account_acct=${accountName}`, process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const activityPubSlice = createSlice({
  name: "activityPub",
  initialState: {
    status: "idle",
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityPubAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivityPubAccount.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(fetchActivityPubAccount.rejected, (state) => {
        state.status = "idle";
      });
  },
});

export const selectActivityPubAccountStatus = (state) => state.status;

export default activityPubSlice.reducer;
