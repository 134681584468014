import { handleResponse } from "../_helpers";

function discordLogin(discordCode) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: discordCode,
      root_path: process.env.REACT_APP_ROOT_PATH,
    }),
  };

  let url = new URL("/api/auth/login/discord", process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
}

export const authenticationService = {
  discordLogin,
};
