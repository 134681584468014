import React from "react";
import { Link } from "react-router-dom";

import { PencilAltIcon } from "@heroicons/react/outline";

import { useSelector } from "react-redux";
import { selectGuildById } from "../../features/guilds/guildsSlice";
import { GuildAvatarImage } from "../../components/GuildAvatarImage";
// import { CalendarTypeImage } from "../../components/CalendarTypeImage";

const NotifiersList = ({ notifiers }) => {
  return (
    <div>
      <div className="flex justify-between pt-6 pb-4">
        <h3 className="text-blue-500 font-semibold text-lg pl-2">Current Notifiers</h3>
        <Link
          key="add-notifier"
          to="/notifier/new"
          className="rounded shadow bg-green-200 hover:bg-green-100 px-2 py-1 flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
          </svg>
          Add Notifier
        </Link>
      </div>
      <div className="hidden sm:flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className=" min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="pl-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Server
                    </th>
                    <th
                      scope="col"
                      className="pl-20 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Followed Account
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notifiers.map((notifier, notifierIdx) => (
                    <NotifierTableRow key={notifier.lookup_id} notifier={notifier} notifierIdx={notifierIdx} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="flex sm:hidden flex-col space-y-4">
        {notifiers.map((notifier, notifierIdx) => (
          <NotifierCard key={notifier.lookup_id} notifier={notifier} />
        ))}
      </div>
    </div>
  );
};

function NotifierCard({ notifier }) {
  const guild = useSelector((state) => selectGuildById(state, notifier.guild.id));

  // TODO - Temp code until multiple connections added to UI
  const firstConnection = notifier.followed_accounts[0];

  return (
    <div className="relative bg-gray-50 rounded-xl shadow overflow-hidden">
      <div
        className={`absolute right-0 top-0 px-3 text-xs leading-5 font-semibold rounded-bl ${
          notifier.enabled ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
        }`}
      >
        {notifier.enabled ? "Enabled" : "Disabled"}
      </div>

      <div className="px-6 pt-2 text-gray-800 w-4/5 truncate">{notifier.name}</div>

      <div className="flex  flex-wrap ">
        <div className="px-6 py-5 whitespace-nowrap">
          <div className="flex items-center t-2">
            <div className="flex-shrink-0 h-10 w-10">
              <GuildAvatarImage guild={guild} name={guild?.name || notifier.guild.name} />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {guild?.name || notifier.guild.name || notifier.guild.id}
              </div>
              <div className="text-sm text-gray-500">{notifier.channel.name || notifier.channel.id}</div>
            </div>
          </div>
        </div>

        <div className="px-6 py-5 whitespace-nowrap">
          <div className="flex items-center t-2">
            <div className="flex-shrink-0 h-10 w-10">
              {/* <CalendarTypeImage className="h-10 mr-2" calendarType={firstConnection.type} /> */}
            </div>
            <div className="ml-4">
              <div className="text-sm text-gray-900">{firstConnection.acct}</div>
              <div className="text-sm text-gray-500">{firstConnection.display_name}</div>
            </div>
          </div>
        </div>

        <div className="flex-1 px-4 py-5 whitespace-nowrap flex flex-col justify-end items-end text-right">
          <Link
            key={notifier.lookup_id}
            to={`/notifier/${notifier.lookup_id}`}
            className="text-sky-500 hover:text-sky-600 transition-colors ease-in-out"
          >
            <PencilAltIcon className="h-6 w-6" />
          </Link>
        </div>
      </div>
      {notifier.error_messages?.length > 0 ? (
        <div className="px-6 pb-4">
          <div className="px-2 py-1 bg-red-300 rounded-lg">
            <div className="font-semibold pb-1.5">Errors occuring with this notifier:</div>
            <ul className="list-disc ml-4">
              {notifier.error_messages.map((msg, i) => (
                <li key={i}>{msg}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function NotifierTableRow({ notifier, notifierIdx }) {
  const guild = useSelector((state) => selectGuildById(state, notifier.guild.id));

  // TODO - Temp code until multiple connections added to UI
  const firstConnection = notifier.followed_accounts[0];

  return (
    <>
      <tr className={notifierIdx % 2 === 0 ? "bg-gray-50" : "bg-gray-100"}>
        <td className="px-6 pt-4 pb-1.5 span" colSpan={4}>
          <div className="text-gray-800 max-w-xl truncate">{notifier.name}</div>
        </td>
      </tr>
      <tr className={`${notifierIdx % 2 === 0 ? "bg-gray-50" : "bg-gray-100"}`}>
        <td className="px-6 pb-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <GuildAvatarImage guild={guild} name={guild?.name || notifier.guild.name} />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {guild?.name || notifier.guild.name || notifier.guild.id}
              </div>
              <div className="text-sm text-gray-500">{notifier.channel.name || notifier.channel.id}</div>
            </div>
          </div>
        </td>
        <td className="px-6 pb-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {/* <CalendarTypeImage className="h-10 mr-2" calendarType={firstConnection.type} /> */}
            </div>
            <div className="ml-4">
              <div className="text-sm text-gray-900">{firstConnection.acct}</div>
              <div className="text-sm text-gray-500">{firstConnection.display_name}</div>
            </div>
          </div>
        </td>
        <td className="px-6 pb-4 whitespace-nowrap">
          <span
            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
              notifier.enabled ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            }`}
          >
            {notifier.enabled ? "Enabled" : "Disabled"}
          </span>
        </td>
        <td className="flex justify-between items-center px-6 py-2 whitespace-nowrap text-sm font-medium">
          <Link
            key={notifier.lookup_id}
            to={`/notifier/${notifier.lookup_id}`}
            className="text-sky-500 hover:text-sky-600 transition-colors ease-in-out"
          >
            <PencilAltIcon className="h-6 w-6" />
          </Link>
        </td>
      </tr>
      {notifier.error_messages?.length > 0 ? (
        <tr className={notifierIdx % 2 === 0 ? "bg-gray-50" : "bg-gray-100"}>
          <td className="px-6 pb-4 span" colSpan={4}>
            <div className="px-4 py-1 bg-red-300 rounded-lg">
              <div className="font-semibold pb-1.5">Errors are occuring with this event notifier:</div>
              <ul className="list-disc ml-4">
                {notifier.error_messages.map((msg, i) => (
                  <li key={i}>{msg}</li>
                ))}
              </ul>
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
}

export { NotifiersList };
