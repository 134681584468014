const SubscriptionBlockedPanel = ({ blockedReason }) => {
  return (
    <div className="flex-1 w-full max-w-screen-xl mx-auto">
      <div className="bg-gray-200">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-7xl mx-auto text-center px-2 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-3 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-700 uppercase tracking-wider">Premium Plans</h2>
              <p className="text-2xl font-extrabold text-black sm:text-4xl lg:text-5xl">
                Support the Development of <span className="whitespace-nowrap">Pachy Bot</span>
              </p>
              <p className="text-xl text-gray-600">
                Unlock all of Pachy's features while also supporting us as we make further enhancements.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 bg-gray-200 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-200" />
            <div className="relative z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                <div className="col-span-2 flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 sm:px-10 py-8 lg:py-5 bg-gray-50">
                    <div>
                      <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-bold tracking-wide uppercase bg-red-300 text-black">
                        User Blocked From Premium Subscriptions
                      </h3>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">
                      Your user has been blocked from subscribing to Pachy Bot's premium plans. If you think this was
                      done in error or you wish to speak with us, please reach out to our{" "}
                      <a href="mailto:support@pachy.social" className="text-sky-500 hover:underline">
                        support team
                      </a>
                      .
                    </p>
                  </div>
                  {blockedReason?.length > 0 ? (
                    <div className="flex-1 flex flex-col justify-between px-6 sm:px-10 py-4 bg-gray-100 space-y-2 ">
                      <h3 className="text-lg font-semibold">Blocked Reason:</h3>
                      <div className="ml-4">{blockedReason}</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SubscriptionBlockedPanel };
