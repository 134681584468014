import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { authHeader, handleResponse } from "../../_helpers";

export const fetchGuilds = createAsyncThunk(
  "guilds/fetchGuildsStatus",
  async ({ refresh = false, guild_id = null }, { getState }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = { method: "GET", headers: authHeader(jwtToken) };
    let url = new URL("/api/user/guilds", process.env.REACT_APP_API_PATH);
    if (refresh) {
      url.searchParams.append("refresh", true);
    }
    if (guild_id) {
      url.searchParams.append("guild_id", guild_id);
    }

    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const fetchGuildChannels = createAsyncThunk(
  "guilds/fetchGuildChannelsStatus",
  async (guild_id, { getState, dispatch }) => {
    let jwtToken = getState().user.jwt;
    dispatch(setActiveGuildId(guild_id));

    const requestOptions = { method: "GET", headers: authHeader(jwtToken) };
    let url = new URL(`/api/guilds/${guild_id}/channels`, process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const fetchGuildRoles = createAsyncThunk(
  "guilds/fetchGuildRolesStatus",
  async (guild_id, { getState, dispatch }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = { method: "GET", headers: authHeader(jwtToken) };
    let url = new URL(`/api/guilds/${guild_id}/roles`, process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions).then(handleResponse);
  }
);

export const licenseGuild = createAsyncThunk("subscription/licenseGuildStatus", async (guild_id, { getState }) => {
  let jwtToken = getState().user.jwt;

  const requestOptions = { method: "POST", headers: authHeader(jwtToken) };
  let url = new URL(`/api/guilds/${guild_id}/license`, process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
});

export const unlicenseGuild = createAsyncThunk("subscription/licenseGuildStatus", async (guild_id, { getState }) => {
  let jwtToken = getState().user.jwt;

  const requestOptions = { method: "POST", headers: authHeader(jwtToken) };
  let url = new URL(`/api/guilds/${guild_id}/unlicense`, process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
});

export const guildsSlice = createSlice({
  name: "guilds",
  initialState: {
    status: "idle",
    guilds: [],
    guildRoles: [],
    activeGuildId: null,
    guildChannels: [],
  },
  reducers: {
    setActiveGuildId: (state, action) => {
      state.activeGuildId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // === Guilds ===
      .addCase(fetchGuilds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGuilds.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.guilds == null) {
          state.guilds = [];
        } else {
          state.guilds = action.payload.guilds.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
      })
      .addCase(fetchGuilds.rejected, (state, action) => {
        state.status = "idle";
      })

      // === Channels ===
      .addCase(fetchGuildChannels.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGuildChannels.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.channels == null) {
          state.guildChannels = [];
        } else {
          state.guildChannels = action.payload.channels.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
      })
      .addCase(fetchGuildChannels.rejected, (state, action) => {
        state.status = "idle";
        state.guildChannels = [];
      })

      // === Roles ===
      //.addCase(fetchGuildRoles.pending, (state) => { })
      .addCase(fetchGuildRoles.fulfilled, (state, action) => {
        if (action.payload.roles == null) {
          state.guildRoles = [];
        } else {
          state.guildRoles = action.payload.roles.sort((a, b) => (a.position > b.position ? 1 : -1));
        }
      })
      .addCase(fetchGuildRoles.rejected, (state, action) => {
        state.guildRoles = [];
      });
  },
});

export const { setActiveGuildId } = guildsSlice.actions;

export const selectManagedGuilds = (state) =>
  state.guilds.guilds.filter((a) => (a.permissions & (1 << 5) ? true : false));
// MANAGE_GUILD *	0x0000000020 (1 << 5)	Allows management and editing of the guild

export const selectInstalledGuilds = (state) =>
  state.guilds.guilds.filter((a) => (a.status !== "pending" ? true : false));

export const selectManagedGuildsInstalled = (state) =>
  state.guilds.guilds
    .filter((a) => (a.permissions & (1 << 5) ? true : false))
    .filter((a) => (a.status !== "pending" ? true : false));

// https://redux.js.org/usage/deriving-data-selectors#passing-input-parameters
// https://github.com/reduxjs/reselect#q-how-do-i-create-a-selector-that-takes-an-argument
export const selectGuildById = createSelector([(state) => state.guilds, (state, id) => id], (guildsState, id) =>
  guildsState.guilds.find((guild) => guild.id === id)
);

export const selectActiveGuild = (state) =>
  state.guilds.guilds.find((guild) => guild.id === state.guilds.activeGuildId);

export const selectGuildChannels = (state) => state.guilds.guildChannels;
export const selectGuildRoles = (state) => state.guilds.guildRoles;

export default guildsSlice.reducer;

// https://egghead.io/lessons/react-use-createselector-from-redux-toolkit-to-build-a-memoized-selector
