import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { HashtagIcon, SpeakerphoneIcon, VolumeUpIcon } from "@heroicons/react/outline";

const SelectListChannel = ({ guildChannels, selected, setActiveChannel }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const onSelectionChange = (channel) => {
    setActiveChannel(channel);
  };

  return (
    <Transition
      show={guildChannels.length !== 0}
      enter="transition ease duration-700 transform"
      enterFrom="opacity-0 -translate-y-full"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-1000 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-full"
    >
      <Listbox value={selected} onChange={onSelectionChange}>
        <div className="mt-1 relative">
          <Listbox.Button className="bg-gray-50 text-black relative w-full border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="block truncate">
              {selected?.name ? (
                <div className="flex">
                  <ChannelIcon channelType={selected.type} className="w-5 h-5 text-gray-400 mr-1" />
                  {selected.name}
                </div>
              ) : (
                <div>Select a Discord Channel...</div>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-50 border border-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {guildChannels.map((channel) => (
                <Listbox.Option
                  key={channel.id}
                  className={({ active }) =>
                    classNames(
                      active ? "text-whitex bg-indigo-50" : "text-gray-900",
                      "cursor-default select-none relative py-2 pl-3 pr-9"
                    )
                  }
                  value={channel}
                >
                  {({ selected, active }) => (
                    <div className="flex space-x-2">
                      <ChannelIcon channelType={channel.type} className="w-5 h-5 text-gray-400" />
                      <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                        {channel.name}
                      </span>

                      {selected ? (
                        <span className="absolute text-green-500 inset-y-0 right-0 flex items-center pr-4">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </Transition>
  );
};

const ChannelIcon = ({ channelType, className }) => {
  switch (channelType) {
    case 0: // Text
      return <HashtagIcon className={className} />;
    case 2: // Voice
      return <VolumeUpIcon className={className} />;
    case 5: // Announcement
      return <SpeakerphoneIcon className={className} />;
    default:
      return null;
  }
};

export { SelectListChannel, ChannelIcon };
