import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectUserLoggedIn } from "../../features/user/userSlice";

import { BuildDiscordAuthURL } from "../../components/LoginWithDiscord";

const UserLoginPage = (props) => {
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const discordAuthURL = BuildDiscordAuthURL();
  const [delayTimeout, setDelayTimeout] = useState(null);

  useEffect(() => {
    // Cancel existing timeout
    if (delayTimeout !== null) {
      clearTimeout(delayTimeout);
      setDelayTimeout(null);
    }

    if (userLoggedIn) {
      // Redirect to homepage
      props.history.push("/dashboard");
      return;
    } else {
      // Setup timeout for login redirect
      // Delay is added to mitigate delays in redux data sync
      let to = setTimeout(function () {
        window.location.href = discordAuthURL;
      }, 2000);

      setDelayTimeout(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn, discordAuthURL]);

  return <div className="flex-1 mt-14 px-2 max-w-xs mx-auto">Checking your login session...</div>;
};

export { UserLoginPage };
