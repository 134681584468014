import buildUrl from "@googlicius/build-url";

import { DiscordLogo } from "./DiscordLogo";

import { useSelector } from "react-redux";
import { selectUserLoggedIn } from "../features/user/userSlice";

const BuildDiscordAuthURL = () => {
  return buildUrl("https://discord.com/api/oauth2/authorize", {
    queryParams: {
      response_type: "code",
      client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
      scope: "identify email guilds",
      state: "5773059ghq983habn",
      redirect_uri: `${process.env.REACT_APP_ROOT_PATH}/auth/discord/callback`,
      prompt: "consent",
    },
  });
};

const LoginWithDiscord = ({ size }) => {
  const userLoggedIn = useSelector(selectUserLoggedIn);

  let discordAuthURL = userLoggedIn ? "/dashboard" : BuildDiscordAuthURL();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <a
      href={discordAuthURL}
      type="submit"
      className={classNames(
        size === "small" ? "py-2 px-4" : "py-3 px-4",
        size === "wide" ? "px-16 py-4 text-xl" : "",
        "flex w-full rounded-md shadow justify-center items-center bg-blurple text-black font-semibold hover:bg-blurple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blurple-light focus:ring-offset-gray-900"
      )}
    >
      {size === "small" ? null : <DiscordLogo classNames="mr-4 h-7" />}
      <div>Login {size === "small" ? null : "with Discord"}</div>
    </a>
  );
};

export { LoginWithDiscord, BuildDiscordAuthURL };
