import { Link } from "react-router-dom";
import { ExclamationIcon } from "@heroicons/react/outline";

export const WarningLicenseRequired = ({ className, text }) => (
  <div>
    <div
      className={`${className} flex justify-center items-center text-sm mx-0.5x px-3 py-2 border-2 border-yellow-500 border-dashed rounded-lg`}
    >
      <ExclamationIcon className="mr-3 text-yellow-500 w-10 h-10" />
      <div className="text-yellow-500">{text}</div>
    </div>
    <div className="text-sm mt-0.5 text-right">
      Unlock additional with a{" "}
      <Link to="/premium" className="text-sky-500 hover:text-sky-600 underline">
        premium server license
      </Link>
    </div>
  </div>
);
