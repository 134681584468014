import { useState, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/outline";

const currencies = [
  { id: "usd", name: "US Dollars", symbol: "$" },
  { id: "eur", name: "Euros", symbol: "€" },
  { id: "gbp", name: "British Pound Sterling", symbol: "£" },
];

const SelectCurrency = ({ selection, setSelection }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(currencies[0]);

  useEffect(() => {
    if (selection === null) {
      var defaultCurrency = DetermineDefaultCurrency();
      let currency = currencies.find((c) => {
        return c.id === defaultCurrency;
      });

      if (!currency) {
        currency = currencies[0];
      }

      setSelection(currency);
      return;
    }

    setSelectedCurrency(selection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const onCurrencyChanged = (currency) => {
    setSelection(currency);
  };

  return (
    <Listbox value={selectedCurrency} onChange={onCurrencyChanged}>
      <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-gray-50 border border-gray-200 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
        <span className="block truncate">
          {selectedCurrency.symbol} {selectedCurrency.name}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
        </span>
      </Listbox.Button>

      <Listbox.Options className="absolute w-auto py-1 mt-1 overflow-auto text-base bg-gray-50 border border-gray-100 rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
        {currencies.map((currency) => (
          <Listbox.Option
            key={currency.id}
            value={currency}
            className={({ active }) =>
              `${active ? "text-amber-900 bg-amber-100" : "text-gray-900"}
                            cursor-default select-none relative`
            }
          >
            {({ selected, active }) => (
              <span
                className={`${selected ? "font-semibold" : "font-normal"} ${
                  active ? "bg-indigo-50" : null
                } block truncate py-2 px-4`}
              >
                {currency.symbol} {currency.name}
              </span>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

const DetermineDefaultCurrency = () => {
  var userLang = navigator.language || navigator.userLanguage;
  userLang = userLang.toLowerCase();

  var tokens = userLang.split("-");
  var baseLang = tokens[0];

  switch (userLang) {
    case "en-uk":
    case "en-gb":
      return "gbp";
    default:
    // Keep searching
  }

  switch (baseLang) {
    case "de": // Germany, Austria, Luxembourg
    case "el": // Greece, Cyprus
    case "es": // Spain
    case "et": // Estonia
    case "fi": // Finland
    case "fr": // France
    case "ga": // Ireland
    case "it": // Italy
    case "lt": // Lithuania
    case "lv": // Latvia
    case "mt": // Malta
    case "nl": // Belgium, the Netherlands
    case "pt": // Portugal
    case "sk": // Slovakia
    case "sl": // Slovenia
      return "eur";
    default:
      return "usd";
  }
};

export { SelectCurrency };
