import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { authHeader, handleResponse } from "../../_helpers";

export const fetchNotifiers = createAsyncThunk("notifiers/fetchNotifiersStatus", async (arg, { getState }) => {
  let jwtToken = getState().user.jwt;

  const requestOptions = { method: "GET", headers: authHeader(jwtToken) };

  let url = new URL("/api/notifiers", process.env.REACT_APP_API_PATH);
  return fetch(url, requestOptions).then(handleResponse);
});

export const addNotifier = createAsyncThunk(
  "google/addNotifierStatus",
  async (notifierData, { getState, dispatch }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = {
      method: "POST",
      headers: authHeader(jwtToken),
      body: JSON.stringify(notifierData),
    };

    let url = new URL("/api/notifiers", process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        dispatch(fetchNotifiers());
        return response;
      });
  }
);

export const checkNotifierPermissions = createAsyncThunk(
  "google/checkNotifierPermissionsStatus",
  async (notifierData, { getState, dispatch }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = {
      method: "POST",
      headers: authHeader(jwtToken),
      body: JSON.stringify(notifierData),
    };

    let url = new URL("/api/notifiers/check_permissions", process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      });
  }
);

export const deleteNotifier = createAsyncThunk(
  "google/addNotifierStatus",
  async (notifierData, { getState, dispatch }) => {
    let jwtToken = getState().user.jwt;

    const requestOptions = {
      method: "DELETE",
      headers: authHeader(jwtToken),
      body: JSON.stringify(notifierData),
    };

    let url = new URL("/api/notifiers", process.env.REACT_APP_API_PATH);
    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((response) => {
        dispatch(fetchNotifiers());
        return response;
      });
  }
);

export const notifiersSlice = createSlice({
  name: "notifiers",
  initialState: {
    status: "idle",
    notifiers: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifiers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifiers.fulfilled, (state, action) => {
        state.status = "idle";
        state.notifiers =
          action.payload.notifiers == null
            ? []
            : action.payload.notifiers.sort((a, b) => {
                if (a.name === "") return 1;
                if (b.name === "") return -1;
                return a.name > b.name ? 1 : -1;
              });
      })
      .addCase(fetchNotifiers.rejected, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectNotifierById = createSelector(
  [(state) => state.notifiers.notifiers, (notifiers, lookup_id) => lookup_id],
  (notifiers, lookup_id) => notifiers.find((notifier) => notifier.lookup_id === lookup_id)
);

export const selectNotifiers = (state) => state.notifiers.notifiers;
export const selectNotifiersStatus = (state) => state.notifiers.status;

export default notifiersSlice.reducer;
