import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SelectEditHandlingType = ({ selectedID, onSelectionIDChange }) => {
  const [selected, setSelected] = useState([]);
  const linkOptions = [
    { name: "Edit in place", id: 0 },
    { name: "Delete and renotify", id: 1 },
    { name: "Retain and add edited post", id: 2 },
  ];

  useEffect(() => {
    let option = linkOptions.find((opt) => {
      return opt.id === selectedID;
    });
    setSelected(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedID]);

  const onSelectionChange = (option) => {
    onSelectionIDChange(option?.id || 0);
  };

  return (
    <Listbox value={selected} onChange={onSelectionChange}>
      <div className="mt-1 relative">
        <Listbox.Button className="bg-gray-50 text-black relative w-full border border-gray-200 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <span className="block truncate">{selected?.name || "Select edit handling type..."}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-50 border border-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {linkOptions.map((option) => (
              <Listbox.Option
                key={option.id}
                className={({ active }) =>
                  classNames(active ? "bg-indigo-50" : "text-gray-900", "cursor-default select-none relative py-2 px-3")
                }
                value={option}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex justify-between">
                      <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
                        {option.name}
                      </span>

                      <div className="flex">
                        {selected ? (
                          <span className="absolute text-green-500 inset-y-0 right-0 flex items-center pr-4">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : (
                          <CheckIcon className="h-5 w-5 invisible" aria-hidden="true" />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export { SelectEditHandlingType };
