import { CheckIcon } from "@heroicons/react/outline";
import { useState } from "react";
import * as Sentry from "@sentry/react";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createStripeSession } from "../../features/subscription/subscriptionSlice";
import { SelectCurrency } from "./SelectCurrency";

import pricingData from "./prices.json";

const tiers = [
  {
    id: "premium",
    name: "Premium",
    description: "Enhance your Discord server's notifications by following multiple accounts.",
    features: ["Pachy Premium for 2 Servers", "Unlimited Followed Users per Server", "Early access to new features"],
    priceMonthly: 5,
    priceYearly: 50,
  },
  {
    id: "premium-pro",
    name: "Premium Pro",
    description: " Do you manage a lot of servers? This level includes advanced notifiers for ten of your servers.",
    features: [
      "Pachy Premium for 10 Servers",
      "Unlimited Followed Accounts per Server",
      "Early access to new features",
    ],
    priceMonthly: 15,
    priceYearly: 150,
  },
];

const SubscriptionLevelsPanel = (props) => {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const stripeCreateSession = (tierID, term) => {
    var priceID;
    try {
      var env =
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" ||
        process.env.REACT_APP_DEVELOPMENT_PRICING === "true"
          ? "dev"
          : "prod";
      priceID = pricingData[env][selectedCurrency.id][tierID][term];
    } catch (err) {
      Sentry.captureException(err);
      priceID = pricingData["prod"]["usd"]["premium"]["monthly"];
    } finally {
      dispatch(createStripeSession(priceID)).then((result) => {
        if (result.meta.requestStatus === "rejected") {
          toast.error(result.error.message);
          return;
        }
        window.location.href = result.payload.redirect;
      });
    }
  };

  return (
    <div className="flex-1 w-full max-w-screen-xl mx-auto">
      <div className="bg-gray-200">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-7xl mx-auto text-center px-2 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-3 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-700 uppercase tracking-wider">Premium Plans</h2>
              <p className="text-2xl font-extrabold text-black sm:text-4xl lg:text-5xl">
                Support the Development of <span className="whitespace-nowrap">Pachy Bot</span>
              </p>
              <p className="text-xl text-gray-600">
                Unlock all of Pachy's features while also supporting us as we make further enhancements.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 bg-gray-200 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-200" />
            <div className="relative z-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="flex justify-end max-w-md mx-auto space-y-4 lg:max-w-5xl pb-4">
                <SelectCurrency selection={selectedCurrency} setSelection={setSelectedCurrency} />
              </div>

              <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                <div className="col-span-2 flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 sm:px-10 py-8 lg:py-5 bg-gray-50">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-bold tracking-wide uppercase bg-gray-300 text-black"
                        id="tier-standard"
                      >
                        Free Tier
                      </h3>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">
                      A single limited post notifier may be generated per server. <br className="hidden lg:block" />
                      Great for small Discord servers with simple following requirements.
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 sm:px-10 py-4 bg-gray-100 space-y-6 ">
                    <ul className="grid lg:grid-cols-2">
                      <li className="flex items-start mr-2 mb-4">
                        <div className="flex-shrink-0">
                          <CheckIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-base text-gray-700">One Notifier per Server</p>
                      </li>

                      <li className="flex items-start mr-2 mb-4">
                        <div className="flex-shrink-0">
                          <CheckIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-base text-gray-700">One Followed Account per Notifier</p>
                      </li>
                    </ul>
                  </div>
                </div>

                {tiers.map((tier) => (
                  <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 py-8 bg-gray-50 sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className="inline-flex px-4 py-1 rounded-full text-sm font-bold tracking-wide uppercase bg-yellow-300 text-black"
                          id={tier.id}
                        >
                          {tier.name}
                        </h3>
                      </div>
                      <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                        {selectedCurrency?.symbol}
                        {tier.priceMonthly}
                        <span className="ml-1 text-2xl font-medium text-gray-500">/mo</span>
                      </div>
                      <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                    </div>
                    <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                            </div>
                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                          </li>
                        ))}
                      </ul>

                      <div className="flex flex-col justify-center">
                        <button
                          onClick={() => stripeCreateSession(tier.id, "monthly")}
                          className="rounded-md shadow block-inline items-center justify-center px-5 py-3 border border-transparent text-lg font-semibold text-gray-900 bg-blue-300 hover:bg-blue-200 transition-colors ease-in-out"
                          aria-describedby="tier-standard"
                        >
                          Subscribe Now to {tier.name}
                        </button>
                        <div
                          onClick={() => stripeCreateSession(tier.id, "yearly")}
                          className="mt-2 self-center underline text-gray-900 hover:text-gray-600 transition-colors ease-in-out cursor-pointer"
                        >
                          Save 15% if you pay annually
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="rounded-lg bg-gray-50 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                <div className="flex-1">
                  <div>
                    <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-gray-200 text-gray-800">
                      Discounted
                    </h3>
                  </div>
                  <div className="mt-4 text-lg text-gray-600">
                    Get full access to all of standard license features for solo projects that make less than $20k gross
                    revenue for <span className="font-semibold text-gray-900">$29</span>.
                  </div>
                </div>
                <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                  <a
                    href="mailto:support@tavern.at"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-indigo-400 hover:bg-indigo-500"
                  >
                    Buy Discounted License
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export { SubscriptionLevelsPanel };
