import React from "react";

import { AddDiscordGuild } from "../../components/AddDiscordGuild";
import { GuildAvatarImage } from "../../components/GuildAvatarImage";

const GuildList = ({ guilds }) => {
  return (
    <div>
      <ul className="flex flex-wrap items-center justify-center">
        {guilds.map((guild) => (
          <li key={guild.id} className="w-11/12 sm:w-1/2 lg:w-1/3">
            <div className="mx-2 my-2 bg-gray-100 rounded-lg shadow divide-y divide-gray-200">
              <div className="w-full flex items-center justify-between p-6 space-x-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 font-semibold truncate">{guild.name}</h3>
                    {guild.owner ? (
                      <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">
                        Owner
                      </span>
                    ) : (
                      <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        Manager
                      </span>
                    )}
                  </div>
                  <p className="mt-1 text-gray-500 text-sm truncate">
                    {guild.status === "ok" ? "Bot installed" : `Bot install ${guild.status}`}
                  </p>
                </div>
                <GuildAvatarImage guild={guild} />
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-700">
                  <div className="w-0 flex-1 flex">
                    <div className="relative cursor-pointer -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sky-500 hover:bg-gray-200 transition-colors font-bold border border-gray-50 rounded-b-lg">
                      <AddDiscordGuild
                        text={guild.status === "ok" ? "Re-Add Bot to Server" : "Add Bot to Server"}
                        guildId={guild.id}
                        popup={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { GuildList };
