export const Footer = () => {
  return (
    <footer className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-2 sm:py-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex flex-wrap justify-center space-x-6 md:order-2">
          <a
            href="https://discord.gg/nWJCsV9hPq"
            className="order-last sm:order-none text-sky-500 hover:text-sky-600 mx-4 py-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Community Server
          </a>

          <a href="https://pachy.social/legal/privacy-policy" className="text-sky-500 hover:text-sky-600 mx-4 py-1">
            Privacy Policy
          </a>

          <a
            href="https://pachy.social/legal/terms-and-conditions"
            className="text-sky-500 hover:text-sky-600 mx-4 py-1"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="pt-1  md:order-1">
          <p className="text-center text-base text-gray-400">
            &copy; 2023{" "}
            <a href="https://tavern.at" className="text-sky-500 hover:text-sky-600">
              At The Tavern
            </a>
            . All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
