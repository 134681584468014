import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../features/user/userSlice";

const UserLogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
    window.location.href = "https://pachy.social";
  });

  return <div></div>;
};

export { UserLogoutPage };
