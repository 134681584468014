import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "@headlessui/react";

import { XCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";

// import { SelectListGoogleAccount } from "./SelectListGoogleAccount";
// import { SelectListGoogleCalendar } from "./SelectListGoogleCalendar";

// import { CalendarTypeImage } from "../../../components/CalendarTypeImage";

import { fetchActivityPubAccount } from "../../../features/activitypub/activityPubSlice";
import { toast } from "react-toastify";
import { trackGoal } from "fathom-client";

// import {
//   selectGoogleAccounts,
//   selectActiveGoogleAccount,
//   setSelectedAccountId,
//   fetchGoogleCalendars,
//   selectGoogleCalendars,
// } from "../../../features/google/googleSlice";
// import { SelectListCalendarType } from "./Sele ctListCalendarType";

const FollowedAccounts = ({ followedAccounts, setFollowedAccounts }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const addFollowedAccount = (followed) => {
    console.warn("addfollowed", followed);
    console.warn("addfollowed", followed.acct);
    for (const f of followedAccounts) {
      if (f.acct === followed.acct) {
        return "Duplicate accounts can not be added";
      }
    }

    let accounts = [...followedAccounts];
    accounts.push(followed);
    setFollowedAccounts(accounts);
    return null;
  };

  const removeAccount = (index) => {
    let accounts = [...followedAccounts];
    accounts.splice(index, 1);
    setFollowedAccounts(accounts);
  };

  return (
    <div className="flex-1 sm:mr-4 sm:w-72 md:w-80">
      <div className="flex justify-between pb-2">
        <h2 className="text-blue-400 font-semibold">Followed Accounts:</h2>
        <div
          onClick={() => setIsDialogOpen(true)}
          className="text-sm font-semibold flex justify-center items-center rounded bg-green-300 hover:bg-green-200 px-1 py-0.5 cursor-pointer transition-colors ease-in-out"
        >
          <PlusCircleIcon className="h-5 mr-1" />
          <div>Add</div>
        </div>
      </div>

      <div
        style={{ minHeight: 5.2 + "em" }}
        className="flex flex-wrap bg-gray-200 border border-gray-300 rounded px-2 pt-2 pb-1 overflow-hidden"
      >
        {followedAccounts.map((followed, index) => (
          <div
            key={index}
            className="flex h-6 text-sm bg-gray-50 rounded-full mr-1 mb-1 px-1 py-0.5 justify-center items-center"
          >
            {/* <CalendarTypeImage className="h-5 mr-1" calendarType={connection.type} /> */}
            {/* TODO - show on hover? {connection.source.name} */}
            <div style={{ maxWidth: 18 + "em" }} className="truncate">
              {followed.acct}
            </div>
            <XCircleIcon onClick={() => removeAccount(index)} className="h-5 ml-1 text-red-400 cursor-pointer" />
          </div>
        ))}

        {followedAccounts.length === 0 ? (
          <div
            onClick={() => setIsDialogOpen(true)}
            className="text-sm font-semibold flex justify-center items-center rounded bg-green-300 hover:bg-green-200 px-2 py-1 mt-4 mb-5 mx-auto cursor-pointer transition-colors ease-in-out"
          >
            <PlusCircleIcon className="h-5 mr-1" />
            <div>Follow an Account</div>
          </div>
        ) : null}

        <FollowAccountModal isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} addFollowedAccount={addFollowedAccount} />
      </div>
    </div>
  );
};

const FollowAccountModal = ({ isOpen, setIsOpen, addFollowedAccount }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(true);

  const [accountName, setAccountName] = useState("");

  // const [calendarType, setCalendarType] = useState(null);

  // const googleAccounts = useSelector(selectGoogleAccounts);
  // const activeGoogleAccount = useSelector(selectActiveGoogleAccount);
  // const setActiveGoogleAccount = (googleAccount) => dispatch(fetchGoogleCalendars(googleAccount.id));

  // const calendars = useSelector(selectGoogleCalendars);
  // const [activeCalendar, setActiveCalendar] = useState();

  let isValid = accountName;

  // useEffect(() => {
  //   setAccountName("");
  // }, [accountName]);

  const onSave = () => {
    // dispatch(fetchActivityPubAccount(accountName));
    // const followed = useSelector(selectActivityPubAccount);

    let followed;

    let fetchActivityPubAccountPromise = dispatch(fetchActivityPubAccount(accountName)).then((fetchResult) => {
      if (fetchResult.meta.requestStatus === "rejected") {
        isValid = false;
        setErrorMessage("Account not found");
        return Promise.reject(fetchResult.error);
      } else {
        if (fetchResult.payload.account.length === 0) {
          isValid = false;
          setErrorMessage("Account not found");
          return Promise.reject(fetchResult.payload);
        }

        // Account is valid
        followed = fetchResult.payload.account;
        console.warn("followed", followed);

        if (!isValid) {
          return;
        }

        let addResult = addFollowedAccount(followed);
        if (addResult === null) {
          // Fathom: Followed ActivityPub Account
          trackGoal("REVVQEGI");
          cleanup();
        } else {
          setErrorMessage(addResult);
        }
      }
    });

    toast.promise(fetchActivityPubAccountPromise, {
      pending: "Looking for account...",
      success: "Account found!",
      error: {
        render({ data }) {
          return data.message;
        },
      },
    });
  };

  const onCancel = () => {
    cleanup();
  };

  const cleanup = () => {
    // dispatch(setSelectedAccountId(null));
    // setActiveCalendar(null);
    setAccountName("");
    setErrorMessage(null);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="text-black fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />

        <div className="relative bg-gray-100 rounded border border-gray-300 max-w-sm mx-auto p-4">
          <Dialog.Title className="font-semibold border-b border-gray-300 pb-2 mb-4">Follow an Account</Dialog.Title>

          <Dialog.Description className="text-sm mb-4">
            Follows a new account with this notifier. Posts from this account will be sent to the selected channel.
          </Dialog.Description>

          <div className="space-y-2">
            <div className="font-semibold text-1xl mx-1">
              <input
                type="text"
                placeholder="Account name"
                value={accountName}
                onChange={(e) => {
                  // if (e.target.value !== "") {
                  //   isValid = true;
                  // }
                  setErrorMessage(null);
                  setAccountName(e.target.value);
                }}
                className="w-full pl-3 pr-10 py-2 bg-gray-50 text-black rounded-xl focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            {/* <SelectListCalendarType selected={calendarType} setSelected={setCalendarType} />
            <SelectListGoogleAccount
              googleAccounts={googleAccounts}
              selected={activeGoogleAccount}
              setActiveGoogleAccount={setActiveGoogleAccount}
            />
            <SelectListGoogleCalendar
              calendars={calendars}
              selected={activeCalendar}
              setActiveCalendar={setActiveCalendar}
            /> */}
          </div>

          {errorMessage ? <div className="text-red-400 ml-2 my-2">{errorMessage}</div> : null}

          <div className="flex justify-end mt-4">
            <button className="bg-red-400 font-semibold rounded mx-1 px-4 py-1" onClick={onCancel}>
              Cancel
            </button>
            <button
              disabled={!isValid}
              className="bg-green-400 disabled:bg-gray-300 font-semibold rounded mx-1 px-6 py-1"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { FollowedAccounts };
