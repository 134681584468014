import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "qs";

import * as Sentry from "@sentry/react";
import { trackGoal } from "fathom-client";

import { authenticationService } from "../../_services";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, logoutUser, selectUserLoggedIn } from "../../features/user/userSlice";

const DiscordCallbackPage = (props) => {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const location = useLocation();

  let params = parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    // window.location.href =
    if (userLoggedIn) {
      props.history.push("/dashboard");
      return;
    }

    if (params.error === "access_denied") {
      window.location.href = "https://pachy.social";
      return;
    }

    authenticationService.discordLogin(params.code).then(
      (response) => {
        dispatch(loginUser(response.jwt));
        // Fathom: Login
        trackGoal("RXCW4KKR");
        const { from } = props.location.state || { from: { pathname: "/" } };
        props.history.push(from);
      },
      (error) => {
        console.log("ERROR DISCORD LOGIN", error);
        Sentry.captureException(error);
        dispatch(logoutUser());
        window.location.href = "https://pachy.social";
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-20">
        <div className="font-semibold text-xl">Updating Your User Data</div>
        <div className="font-semibold text-lg">This will only take a few moments...</div>
        <svg
          className="animate-spin h-12 w-12 text-gray-50 mt-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>
  );
};
export { DiscordCallbackPage };
