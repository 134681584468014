import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectUserLoggedIn, selectUser } from "../../features/user/userSlice";
import { LoginWithDiscord } from "../LoginWithDiscord";
import { DiscordLogo } from "../DiscordLogo";

export const TopNav = (props) => {
  const userLoggedIn = useSelector(selectUserLoggedIn);
  const currentUser = useSelector(selectUser);
  const location = useLocation();

  const navigation = [
    { name: "Dashboard", href: "/dashboard", beta: false },
    { name: "Premium", href: "/premium", beta: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function currentPath(path) {
    return location.pathname === path;
  }

  return (
    <Disclosure as="nav" className="bg-gray-100">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <a href="https://pachy.social" className="flex-shrink-0 flex items-center font-bold text-xl">
                  <img className="h-8 w-autox mr-2" src="/images/pachy-dark.png" alt="Pachy Bot Logo" />
                  Pachy Bot
                </a>

                {userLoggedIn ? (
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) =>
                        item.beta && !currentUser.beta_user ? null : (
                          <Link
                            key={`${item.name}-desktop`}
                            to={item.href}
                            className={classNames(
                              currentPath(item.href)
                                ? "bg-gray-50 text-black"
                                : "text-gray-900 hover:bg-gray-200 hover:text-gray-800",
                              "px-3 py-2 rounded-md text-sm font-medium tracking-wide"
                            )}
                            aria-current={currentPath(item.href) ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {userLoggedIn ? (
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-400 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        {currentUser?.avatar ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={
                              currentUser.ident
                                ? `https://cdn.discordapp.com/avatars/${currentUser?.ident}/${currentUser?.avatar}.png`
                                : ""
                            }
                            alt=""
                          />
                        ) : (
                          <DiscordLogo classNames="h-6 w-6 m-1.5 rounded-full text-black" />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-300 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <div className="block px-4 py-2 text-md font-semibold border-b text-sky-500">
                            {currentUser.name}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              key="logout"
                              to="/logout"
                              className={classNames(
                                active ? "bg-gray-500" : "",
                                "block px-4 font-semibold py-2 text-sm text-black"
                              )}
                            >
                              Logout
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div as="div" className="ml-3 relative">
                    <LoginWithDiscord size="small" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) =>
                item.beta && !currentUser.beta_user ? null : (
                  <Link
                    key={`${item.name}-mobile`}
                    to={item.href}
                    className={classNames(
                      currentPath(item.href)
                        ? "bg-gray-50 text-black"
                        : "text-gray-900 hover:bg-gray-200 hover:text-black",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={currentPath(item.href) ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
