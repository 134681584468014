import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import * as Fathom from "fathom-client";

import { history } from "./_helpers";
import { PrivateRoute } from "./common/PrivateRoute";

import { TopNav } from "./components/layout/TopNav";
import { Footer } from "./components/layout/Footer";
import { HomePage } from "./pages/home/HomePage";
import { DiscordCallbackPage } from "./pages/login/DiscordCallbackPage";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import { EditNotifierPage } from "./pages/notifier/EditNotifierPage";

import { useSelector, useDispatch } from "react-redux";
import { fetchUser, selectUserLoggedIn } from "./features/user/userSlice";
// import { setGoogleAuthAvailable } from "./features/google/googleSlice";
import { BotAddedPage } from "./pages/bot/BotAddedPage";

import { PremiumSalePage } from "./pages/premium/PremiumSalePage";
import { AddDiscordBotPage } from "./pages/bot/AddDiscordBotPage";
import { MaintenancePage } from "./pages/maint/MaintenancePage";
import { UserLoginPage } from "./pages/login/UserLoginPage";
import { UserLogoutPage } from "./pages/login/UserLogoutPage";

// Record a pageview when route changes
// ??? https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
// Router.events.on("routeChangeComplete", () => {
//   Fathom.trackPageview();
// });

function App() {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector(selectUserLoggedIn);

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(fetchUser());
    }
  });

  useEffect(() => {
    Fathom.load("SBCCWGNW", {
      url: "https://ideal-whole.pachy.social/script.js",
      // includedDomains: ['pachy.social'],
      // auto: false,
      spa: "auto",
    });
  }, []);

  if (process.env.REACT_APP_MODE === "maintenance") {
    return <MaintenancePage />;
  }

  return (
    <Router history={history}>
      <TopNav />
      <ToastContainer theme="dark" bodyClassName="text-sm" />
      {/* <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_KEY}
        onScriptLoadSuccess={() => {
          // Google OAuth scripts loaded
          dispatch(setGoogleAuthAvailable(true));
        }}
        onScriptLoadError={() => {
          // Google OAuth scripts failed to load; probably blocked
          console.error("Google OAuth Provider error; blocked by ad blocker?");
          dispatch(setGoogleAuthAvailable(false));
        }}
      > */}
      <div className="flex-1">
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <Route exact path="/login" component={UserLoginPage} />
          <Route exact path="/logout" component={UserLogoutPage} />

          <PrivateRoute exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/add-bot" component={AddDiscordBotPage} />

          <PrivateRoute path="/notifier/new" component={EditNotifierPage} />
          <PrivateRoute path="/notifier/:id" component={EditNotifierPage} />

          <PrivateRoute path="/premium" component={PremiumSalePage} />

          <Route exact path="/auth/discord/callback" component={DiscordCallbackPage} />
          <Route exact path="/auth/discord/bot" component={BotAddedPage} />

          <Route component={NotFound} />
        </Switch>
      </div>
      {/* </GoogleOAuthProvider> */}

      <Footer />
    </Router>
  );
}

const NotFound = () => (
  <div className="flex flex-1 justify-center items-center">
    <h1 className="text-4xl font-semibold text-center leading-normal">
      404 <br />
      Page Not Found
    </h1>
  </div>
);

export default App;
