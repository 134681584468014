import { Transition } from "@headlessui/react";

const ErrorMessage = ({ valid, message, items, className }) => {
  return (
    <Transition
      show={!valid}
      enter="transition duration-200 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-200 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-200 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <li className={`${className} px-4x sm:px-8x text-sm text-red-300 rounded font-semibold`}>
        <div>{message}</div>
        {items ? (
          <ul className="list-inside px-2">
            {items?.map((item) => {
              return <li key={item}>{item}</li>;
            })}
          </ul>
        ) : null}
      </li>
    </Transition>
  );
};

export { ErrorMessage };
